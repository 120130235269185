import { ChevronDownIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import { Avatar, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import NavLogo from "src/assets/img/navLogo.svg?react";
import ProfilePictureLogo from "src/assets/img/profilePictureLogo.svg?react";

import "./navbar.scss";

const EmptyNavbar = (): JSX.Element => {
  const { t } = useTranslation(["shared", "signature", "phoneRecording"]);

  const tempLinks = [
    "Conversations",
    "Signatures",
    t("phoneRecording", { ns: "phoneRecording" }),
  ];
  return (
    <nav className="navbar__empty">
      <div className="navbar__leftPart">
        <NavLogo className="navbar__logo" />
        <ul className="navbar__leftMenu">
          {tempLinks.map((navLink) => (
            <Typography key={navLink} className="navbar__title">
              {navLink}
            </Typography>
          ))}
        </ul>
      </div>
      <div className="navbar__rightPart">
        <ul className="navbar__rightMenu">
          <li>
            <Button
              disabled
              className="navbar__button navbar__button--team"
              startIcon={
                <div className="navbar__teamsBadge">
                  <Avatar
                    variant="rounded"
                    className="navbar__teamAvatar"
                    sx={{ width: "2.125rem", height: "2.125rem" }}
                  />
                </div>
              }
              endIcon={<ChevronDownIcon />}
            />
          </li>
          <li>
            <IconButton disabled className="navbar__button">
              <Avatar sx={{ width: "2.125rem", height: "2.125rem" }}>
                <ProfilePictureLogo />
              </Avatar>
            </IconButton>
          </li>
          <li>
            <Button disabled className="navbar__button">
              <Cog6ToothIcon className="navbar__settingIcon" />
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default EmptyNavbar;
